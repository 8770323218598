<template>
  <div class="recommend">
    <div class="recoment-tab text-main row-center pointer">
      <div
        @click="tabClick(item.id)"
        :class="tabbarIndex === item.id ? 'tabClass' : ''"
        v-for="item in simArray"
        :key="item.id"
      >
        {{ item.position_name }}
      </div>
    </div>
    <div class="el-tab-heard space-between">
      <div class="row-center heard-tab-left">
        <div
          v-for="(item, index) in heardTab"
          :key="index"
          class="pointer"
          :class="[
            heardTabIndex === index ? 'heardTabClass' : '',
            heardTabIndex === 0 && heardTabIndex === index ? 'heardCurrent' : '',
          ]"
          @click="headTabClick(index)"
        >
          {{ item.text }} · {{ item.num }}
        </div>
      </div>
      <div class="flex-center el-heard-tab-center">
        <span class="text-white">无需手动搜索，优质人才精准推送</span>
        <img src="../../../assets/glxt/11.png" alt="" />
      </div>
      <div class="font-normal heard-tab-right">
        人才推荐名额剩余
        <span class="text-blue font-light" v-if="accountObj">{{
          accountObj.push_num ? accountObj.push_num : 0
        }}</span>
        个
      </div>
    </div>
    <div class="talent-center">
      <div class="talent-item" v-for="(item, index) in talentList" :key="index">
        <talent-model
          type="recommend"
          :index="index"
          :item="item"
          :tabbarIndex="heardTabIndex"
          :heardTabIndex="heardTabIndex"
          @setLinkStatue="setLinkStatue"
          :content="content"
        ></talent-model>
      </div>
      <div v-if="talentList.length == 0" class="direction no-list">
        <img src="../../../assets/glxt/45.png" class="el-no-list-img" alt="" />
        <span>什么也没有，发布职位靠谱人才马上就位</span>
      </div>
    </div>
  </div>
</template>

<script>
import talentModel from "../components/talent.vue";
import api from "../../../api/enterprises";
import userApi from "../../../api/user";
import recruiterApi from "../../../api/recruiter";
export default {
  components: { talentModel },

  data() {
    return {
      tabbarIndex: "",
      simArray: [],
      heardTab: [
        {
          text: "最新推荐",
          num: 0,
        },
        {
          text: "沟通中",
          num: 0,
        },
        {
          text: "不合适",
          num: 0,
        },
      ],
      heardTabIndex: 0,
      talentList: [],
      query: {
        position_id: "",
        link_status: 0,
        type: 1,
      },
      accountObj: null,
      content:""
    };
  },

  created() {
    this.simpleList();
    this.userAccount();
    this.getSay()
  },

  mounted() {
    
  },

  methods: {
      // 获取打招呼语
    getSay() {
      // console.log('报错')
      recruiterApi.getSay({ type: 1 }).then((res) => {
        if (res.code == 200) {
         if(res.data.data.length>0){
           let data = res.data.data.filter((element, index) => {
            if (element.is_default == 1) {
              return element;
            }
          });
          if(data.length>0){
            this.content=data[0].content
          }else{
            this.content=res.data.data[0].content
          }
         }else{
          this.content='打扰了,我司正在招聘该职位的人才，是否方便沟通一下呢'
         }
        }
      });
    },
    // 获取用户资产
    userAccount() {
      userApi.userAccount().then((res) => {
        if (res.code == 200) {
          this.accountObj = res.data;
        }
      });
    },
    setLinkStatue(){
      this.getPushNum()
      this.posiList()
    },
    getPushNum(id) {
      api.getPushNum({ position_id: id,type:1 }).then((res) => {
        if (res.code == 200) {
          this.heardTab[0].num = res.data.no_link_num ? res.data.no_link_num : 0;
          this.heardTab[1].num = res.data.link_num ? res.data.link_num : 0;
          this.heardTab[2].num = res.data.no_num ? res.data.no_num : 0;
        }
      });
    },
    // 获取相关职位
    posiList() {
      api.perPush(this.query).then((res) => {
        if (res.code == 200) {
          this.talentList = res.data.data;
        }
      });
    },
    // 获取企业发布的职位
    simpleList() {
      let entDetail = JSON.parse(localStorage.getItem("entDetail"));
      let id = entDetail.id;
      api.simpleList({ enterprise_id: id }).then((res) => {
        if (res.code == 200) {
          this.simArray = res.data;
          
          if(res.data.length>0){
            this.tabbarIndex = res.data[0].id;
          this.query.position_id = res.data[0].id;
          this.posiList();
            this.getPushNum(res.data[0].id);
          }
        }
      });
    },
    tabClick(index) {
      this.tabbarIndex = index;
      this.query.position_id = index;
      this.posiList();
      this.getPushNum(index);
    },
    headTabClick(index) {
      this.query.link_status = index;
      this.heardTabIndex = index;
      this.posiList();
    },
  },
};
</script>
<style lang="less" scoped>
.recommend {
  width: 100%;
  padding: 1.875rem 9.1875rem 1.25rem 9.125rem;
  .recoment-tab {
    font-size: 20px;
    margin-bottom: 20px;
    div {
      margin-right: 20px;
    }
    .tabClass {
      color: #126bf9;
      font-size: 24px;
    }
  }
  .el-tab-heard {
    width: 100%;
    height: 39px;
    background: #ffffff;
    padding-left: 62px;
    padding-right: 30px;
    margin-bottom: 30px;
    .heard-tab-left {
      color: #555555;
      font-size: 15px;

      div {
        height: 100%;
        line-height: 2.4375rem;
        position: relative;
        margin-right: 3.4375rem;
        &:after {
          position: absolute;
          content: "";
          width: 0;
          height: 0;
          border-bottom: 4px solid #126bf9;
          left: 0;
          bottom: 0;
          transition: 0.3s;
        }
      }
      .heardTabClass {
        color: #126bf9;
        &:after {
          width: 4rem;
        }
      }
      .heardCurrent {
        &:after {
          width: 5.0625rem;
        }
      }
    }
    .el-heard-tab-center {
      width: 235px;
      height: 30px;
      background: linear-gradient(-2deg, #126bf9 0%, #85b4ff 100%);
      border-radius: 5px;
      font-size: 13px;
      img {
        width: 9px;
        height: 8px;
        margin-left: 10px;
      }
    }
    .heard-tab-right {
      color: #555555;
    }
  }
  .talent-center {
    width: 100%;
    .talent-item {
      width: 100%;
      margin-bottom: 8px;
    }
    .talent-item:last-child {
      margin-bottom: 0;
    }
    .no-list {
      justify-content: center;
      font-weight: 400;
      color: #bfbfbf;
      font-size: 14px;
      margin-top: 90px;
      .el-no-list-img {
        width: 163px;
        height: 145px;
        margin-bottom: 23px;
      }
    }
  }
}
</style>
